import React from 'react';
import { useLocation, useParams } from 'react-router';

import { ArrowRightIcon, Button } from 'evergreen-ui';

const getLocationParams = (location: { search: string }): URLSearchParams | null => {
  const { search } = location;
  if (!search) {
    return null;
  }
  return new URLSearchParams(search);
};

interface ParamsType {
  token: string;
}

function History() {
  const label = `History()`;
  const location = useLocation<{ search: string }>();
  console.log(`${label} - location:`, location);
  const locationParams = getLocationParams(location);
  const redirectUrl = locationParams?.get(`r`);
  let status;
  if (!redirectUrl) {
    status = `Could not capture url.`;
  } else {
    status = `Qlip not loaded, still captured url: ${redirectUrl}`;
  }
  const params = useParams<ParamsType>();
  console.log(`${label} - params:`, params);
  const { token } = params;
  const href = token ? `/q/${token}` : `#`;
  console.log(`${label} - href:`, href);
  return (
    <>
      <h1>History</h1>
      <p>{status}</p>
      <Button is="a" href={href} iconAfter={ArrowRightIcon}>
        Go to history
      </Button>
    </>
  );
}

export { History };

/*
https://stg.qlipped.com/history/MTU5MzQ5MzI1MTc4Nzo3NDdkM2M1OC03MmMyLTQzMzgtYTU0OS04YWJlZWI0Zjg1ZmM6VFc5NmFXeHNZUzgxTGpBZ0tFMWhZMmx1ZEc5emFEc2dTVzUwWld3Z1RXRmpJRTlUSUZnZ01UQmZNVFZmTXlrZ1FYQndiR1ZYWldKTGFYUXZOVE0zTGpNMklDaExTRlJOVEN3Z2JHbHJaU0JIWldOcmJ5a2dRMmh5YjIxbEx6Z3pMakF1TkRFd015NHhNVFlnVTJGbVlYSnBMelV6Tnk0ek5nPT06Og==
 */
