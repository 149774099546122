import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { Button } from 'evergreen-ui';
import { useParams } from 'react-router';

// @ts-ignore
const DialogAccountSignInFooter = (props) => {
  // console.log(`DialogAccountSignInFooter() - props:`, props);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState({ ...props.state });
  const { close } = props;
  const { token } = useParams();
  const buttonContinueWithSignInEmailRef = useRef<HTMLButtonElement>(null);
  const buttonContinueWithSignInPasscodeRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    // console.log(`DialogAccountSignInFooter.useEffect() - props:`, props);
    // hideSignInPasscode();
    // showSignInEmail();
    if (buttonContinueWithSignInPasscodeRef?.current) {
      buttonContinueWithSignInPasscodeRef.current.classList.add(`backstage`);
    }
    if (buttonContinueWithSignInEmailRef?.current) {
      buttonContinueWithSignInEmailRef.current.classList.remove(`backstage`);
    }
  }, [props, state, token]);
  function hideSignInEmail() {
    console.log(`hideSignInEmail() - props:`, props);
    if (props.signInEmailRefFormField?.current) {
      console.log(`hideSignInEmail() -`);
      props.signInEmailRefFormField.current.classList.add(`backstage`);
    }
    if (buttonContinueWithSignInEmailRef?.current) {
      buttonContinueWithSignInEmailRef.current.classList.add(`backstage`);
    }
  }
  function showSignInEmail() {
    console.log(`showSignInEmail() - props`, props);
    if (props.signInEmailRefFormField?.current) {
      console.log(`showSignInEmail() -`);
      props.signInEmailRefFormField.current.classList.remove(`backstage`);
      props.signInEmailRefInput.current.focus();
    }
    if (buttonContinueWithSignInEmailRef?.current) {
      buttonContinueWithSignInEmailRef.current.classList.remove(`backstage`);
    }
  }
  function hideSignInPasscode() {
    if (props.signInPasscodeRefFormField?.current) {
      props.signInPasscodeRefFormField.current.classList.add(`backstage`);
    }
    if (buttonContinueWithSignInPasscodeRef?.current) {
      buttonContinueWithSignInPasscodeRef.current.classList.add(`backstage`);
    }
  }
  function showSignInPasscode() {
    if (props.signInPasscodeRefFormField?.current) {
      props.signInPasscodeRefFormField.current.classList.remove(`backstage`);
      props.signInPasscodeRefInput.current.focus();
    }
    if (buttonContinueWithSignInPasscodeRef?.current) {
      buttonContinueWithSignInPasscodeRef.current.classList.remove(`backstage`);
    }
  }
  function signInWithEmail(e: SyntheticEvent) {
    // console.log(`DialogAccountSignInFooter.signInWithEmail() - props:`, props);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    hideSignInEmail();
    showSignInPasscode();
  }
  function signInWithPasscode(e: SyntheticEvent) {
    // console.log(`DialogAccountSignInFooter.signInWithPasscode() - props:`, props);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    hideSignInPasscode();
    showSignInEmail();
  }
  return (
    <>
      <Button appearance="minimal" marginLeft={8} onClick={close}>
        Cancel
      </Button>
      <Button
        appearance="primary"
        marginLeft={8}
        onClick={signInWithEmail}
        ref={buttonContinueWithSignInEmailRef}
      >
        Continue with email
      </Button>
      <Button
        appearance="primary"
        className="backstage"
        marginLeft={8}
        onClick={signInWithPasscode}
        ref={buttonContinueWithSignInPasscodeRef}
      >
        Continue with passcode
      </Button>
    </>
  );
};
export { DialogAccountSignInFooter };
