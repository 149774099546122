import { RefObject } from 'react';

enum TOGGLE_TYPE {
  SHOW = `SHOT`,
  HIDE = `HIDE`,
}

interface ToggleFormField {
  formFieldClass: string;
  formFieldRef: RefObject<HTMLElement>;
  inputRef?: RefObject<HTMLInputElement>;
  needsFocus?: boolean;
  toggleType: TOGGLE_TYPE;
}

const toggleFormElement = ({
  formFieldClass,
  formFieldRef,
  inputRef,
  needsFocus,
  toggleType,
}: ToggleFormField): void => {
  if (formFieldRef?.current) {
    switch (toggleType) {
      case TOGGLE_TYPE.SHOW: {
        formFieldRef.current.classList.remove(formFieldClass);
        break;
      }
      case TOGGLE_TYPE.HIDE: {
        formFieldRef.current.classList.add(formFieldClass);
        break;
      }
    }
  }
  if (inputRef?.current) {
    if (needsFocus) inputRef.current.focus();
  }
};

export { toggleFormElement, TOGGLE_TYPE };
