import { ArrowUpIcon, Button, Paragraph, Position, Tooltip } from 'evergreen-ui';
import React from 'react';

interface Props {
  token: string;
}

function getDomain() {
  const { REACT_APP_HOST_ENV } = process.env;
  switch (REACT_APP_HOST_ENV) {
    case 'local': {
      return `http://127.0.0.1:3000/dev`;
    }
  }
  const {
    location: { host },
  } = window;
  const prefix = `asset`;
  const subDomain = host
    .replace(/qlipped\.com/g, '')
    .replace(/\.$/g, '')
    .trim();
  const assetSubDomain = subDomain ? `${prefix}-${subDomain}` : prefix;
  return `https://${assetSubDomain}.qlipped.com`;
}

/*
const getOgTitleExample = () => {
  return Array.prototype.slice
    .call(document.getElementsByTagName('meta'))
    .reduce((metas, element) => {
      if (element.attributes) {
        const meta = Array.prototype.slice.call(element.attributes).reduce((meta, attribute) => {
          const { name, value } = attribute;
          meta[name] = value;
          return meta;
        }, {});
        metas.push(meta);
      }
      return metas;
    }, [])
    .filter((meta) => 'og:title' === meta?.property)
    .map((ogTitle) => (ogTitle.content ? btoa(ogTitle.content) : null))
    .find((ogTitle, index) => 0 === index);
};
 */

/*
const getFilteredOgTitleExample = () => {
  return Array.prototype.slice
    .call(document.getElementsByTagName('meta'))
    .reduce((metas, element) => {
      if (element.attributes) {
        const meta = Array.prototype.slice.call(element.attributes).reduce((meta, attribute) => {
          const { name, value } = attribute;
          if (name && value) {
            attribute.name = name.trim();
            attribute.value = value.trim();
            const { name: attributeName, value: attributeValue } = attribute;
            if ('property' === attributeName && 'og:title' === attributeValue) {
              meta[attributeName] = attributeValue;
            } else {
              if ('content' === attributeName && attributeValue) {
                meta[attributeName] = attributeValue;
              }
            }
          }
          return meta;
        }, {});
        if ('og:title' === meta.property && meta.content) {
          metas.push(btoa(meta.content));
        }
      }
      return metas;
    }, [])
    .find((ogTitle, index) => 0 === index);
};
 */

/*
 * Veranda URLs (launch as tab):
 * github.com
 * news.ycombinator.com
 * apps.apple.com
 * basarat.gitbook.io
 */

/*
function unescapeEncode(value) {
  return unescape(encodeURIComponent(value));
}
 */

function BookmarkletLink(props: Props) {
  const { token } = props;
  const domain = getDomain();
  const verandaUrl = `${domain}/pixel/veranda/${token}`;
  const href = `
javascript:(function(){
  var qOpenGraph = {};
  var qTwitter = {};
  function isMetaKeyPush(key) {
    return ['description','title','url'].includes(key);
  };
  function buildMeta(key, value) {
    const meta = {};
    meta[key] = value;
    return meta;
  };
  function isOpenGraphMeta(key, value) {
    return key.indexOf('og:') === 0 && value;
  };
  function isTwitterMeta(key, value) {
    return key.indexOf('twitter:') === 0 && value;
  };
  function stampMeta(key, prefix, map, meta, value) {
    const k = key.replace(prefix, '');
    if (isMetaKeyPush(k)) {
      return meta;
    }
    map[k] = value;
  };
  function getMetas() {
    var properties = [
      'og:video:tag'
    ];
    var itemprops = [
      'regionsAllowed',
      'metadata/x-safeframe'
    ];
    var names = [
      'github-keyboard-shortcuts',
      'google-site-verification',
      'hovercard-subject-tag',
      'html-safe-nonce',
      'js-proxy-site-detection-payload',
      'optimizely-datafile',
      'request-id',
      'selected-link',
      'visitor-hmac',
      'visitor-payload',
    ];
    var descriptions = {};
    var titles = {};
    return Array.prototype.slice
      .call(document.getElementsByTagName('meta'))
      .reduce((metas, element) => {
        const { attributes } = element;
        const meta = Array.prototype.slice.call(attributes).reduce((meta, attribute) => {
          const { name, value } = attribute;
          meta[name] = unescapeEncode(value);
          return meta;
        }, {});
        if (meta.name) {
          const key = meta.name;
          const value = meta.content;
          if (isOpenGraphMeta(key, value)) {
            const m = stampMeta(key, 'og:', qOpenGraph, meta, value);
            if (m) { metas.push(m); };
          } else if (isTwitterMeta(key, value)) {
            const m = stampMeta(key, 'twitter:', qTwitter, meta, value);
            if (m) { metas.push(m); };
          } else {
            metas.push(buildMeta(key, value));
          }
        } else if (meta.property) {
          const key = meta.property;
          const value = meta.content;
          if (isOpenGraphMeta(key, value)) {
            const m = stampMeta(key, 'og:', qOpenGraph, meta, value);
            if (m) { metas.push(m); };
          } else if (isTwitterMeta(key, value)) {
            const m = stampMeta(key, 'twitter:', qTwitter, meta, value);
            if (m) { metas.push(m); };
          } else {
            metas.push(buildMeta(key, value));
          }
        } else {
          metas.push(meta);
        }
        return metas;
      }, [])
      .filter((meta) => !names.includes(meta.name))
      .filter((meta) => !itemprops.includes(meta.itemprop))
      .filter((meta) => !properties.includes(meta.property))
      .filter((meta) => {
        const keys = Object.keys(meta);
        const descriptionKey = keys.find((key) => {
          return ['description'].includes(key)
        });
        if (descriptionKey) {
            const content = meta[descriptionKey];
            if (!descriptions[content]) {
              descriptions[content] = true;
              return true;
            }
            return false;
        }
        const filteredKey = keys.find((key) => {
          return ['name', 'property', 'itemprop'].includes(key)
        });
        if (filteredKey) {
          const value = meta[filteredKey];
          if (value.indexOf('description') >= 0) {
            const content = meta.content;
            if (!descriptions[content]) {
              descriptions[content] = true;
              return true;
            }
            return false;
          }
          if (value.indexOf('title') >= 0) {
            const content = meta.content;
            if (!titles[content]) {
              titles[content] = true;
              return true;
            }
            return false;
          }
        }
        return true;
      });
  };
  function getMetaOgTitle(metas) {
    return metas
      .reduce((metas, element) => {
        if (element.attributes) {
          const meta = Array.prototype.slice.call(element.attributes).reduce((meta, attribute) => {
            const { name, value } = attribute;
            if (name && value) {
              attribute.name = name.trim();
              attribute.value = value.trim();
              const { name: attributeName, value: attributeValue } = attribute;
              if ('property' === attributeName && 'og:title' === attributeValue) {
                meta[attributeName] = unescapeEncode(attributeValue);
              } else {
                if ('content' === attributeName && attributeValue) {
                  meta[attributeName] = unescapeEncode(attributeValue);
                }
              }
            }
            return meta;
          }, {});
          if ('og:title' === meta.property && meta.content) {
            metas.push(meta.content);
          }
        }
        return metas;
      }, [])
      .find((ogTitle, index) => 0 === index);
  };
  function openVeranda(url) {
    var a = document.createElement('a');
    a.href = url;
    var e = document.createEvent('MouseEvents');
    e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, true, false, false, true, 0, null);
    a.dispatchEvent(e);
  };
  function unescapeEncode(value) {
    return unescape(encodeURIComponent(value));
  };
  var metas = getMetas();
  var location = document.location;
  var metaOgTitle = getMetaOgTitle(metas);
  if (metaOgTitle) {
    location.metaOgTitle = metaOgTitle;
  }
  if (metas.length) {
    location.metas = metas;
  }
  location.qOpenGraph = qOpenGraph;
  location.qTwitter = qTwitter;
  console.log({ location });
  var hashed = window.btoa(JSON.stringify(location));
  var [topLevel, secondLevel] = window.location.hostname.split('.').reverse();
  var veranda = ['github.com','ycombinator.com','apple.com','gitbook.io','syncfusion.com','amazon.com','compose.com'];
  if (veranda.includes(secondLevel+'.'+topLevel)) {
    var url = '${verandaUrl}.' + hashed + '.js';
    var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    if (!isChrome) {
      var win = window.open(url, '_blank');
      window.focus();
    } else {
      openVeranda(url);
      window.focus();
    }
  } else {
    var s = document.createElement('script');
    s.type = 'application/javascript';
    s.onerror = function(e) {
      var url = '${verandaUrl}.' + hashed + '.js';
      var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
      if (!isChrome) {
        var win = window.open(url, '_blank');
        window.focus();
      } else {
        openVeranda(url);
        window.focus();
      }
    };
    s.src = '${domain}/script/token/${token}.' + hashed + '.js';
    document.getElementsByTagName('head')[0].appendChild(s);
  }
})();
  `
    .trim()
    .replace(/\r?\n|\r/g, ``)
    .replace(/\s+/g, ` `);
  return (
    <>
      <a href={href} style={{ textDecoration: 'none' }}>
        <Tooltip
          appearance="card"
          content={<Paragraph margin={8}>Drag me to the toolbar & click!</Paragraph>}
          position={Position.BOTTOM_LEFT}
        >
          <Button iconBefore={ArrowUpIcon} marginLeft={16} marginTop={16}>
            Qlipped
          </Button>
        </Tooltip>
      </a>
    </>
  );
}

export { BookmarkletLink };
