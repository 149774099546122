import { STATE_ACCOUNT_DEFAULT, STATE_ACCOUNT_SIGN_IN_SHOW, STATE_ACCOUNT_SIGN_UP_SHOW } from './dialog';
import { SyntheticEvent } from 'react';

const fakeRegister = (setState: Function, window: Window) => {
  window.setTimeout(() => {
    setState(STATE_ACCOUNT_DEFAULT);
  }, 2000);
};

const fakeResend = (setState: Function, window: Window) => {
  window.setTimeout(() => {
    setState(STATE_ACCOUNT_DEFAULT);
  }, 2000);
};

const fakeSignIn = (setState: Function, window: Window) => {
  window.setTimeout(() => {
    setState(STATE_ACCOUNT_DEFAULT);
  }, 2000);
};

const fakeSignOut = (setState: Function, window: Window) => {
  window.setTimeout(() => {
    setState(STATE_ACCOUNT_DEFAULT);
  }, 2000);
};

function register(e: SyntheticEvent, setState: Function) {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  setState(STATE_ACCOUNT_SIGN_UP_SHOW);
}

function signIn(e: SyntheticEvent, setState: Function) {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  setState(STATE_ACCOUNT_SIGN_IN_SHOW);
}

export { fakeRegister, fakeResend, fakeSignIn, fakeSignOut, register, signIn };
