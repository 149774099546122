import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import './index.css';

import App from './App';
import {getApiUrl, isLocalhost} from './helper/api';
import * as serviceWorker from './serviceWorker';
import { getTokenFromDocumentLocation } from './helper/token';

const apiUrl = getApiUrl();
const uri = isLocalhost() ? `http://${apiUrl}/dev/proxy` : `https://${apiUrl}/api/proxy`;
const httpLink = createHttpLink({ uri });
const token = getTokenFromDocumentLocation();
const authorization = token ? `Basic: ${token}` : undefined;
const authLink = setContext((request, previousContext) => ({
  headers: { ...previousContext?.headers, authorization },
}));
const link = authLink.concat(httpLink);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const ApolloApp = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

// ReactDOM.render(
//   <>
//     {/*<React.StrictMode>*/}
//     <App />
//     {/*</React.StrictMode>*/}
//   </>,
//   document.getElementById('root'),
// );

ReactDOM.render(<ApolloApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
