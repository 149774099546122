import { gql } from '@apollo/client';

const DELETE_FOOTPRINT = gql`
  mutation DeleteFootprint($footprintId: String!) {
    footprintDelete(input: { footprintId: $footprintId }) {
      id
    }
  }
`;

const GET_FOOTPRINTS = gql`
  query GetFootprints {
    footprints {
      id
      title
      url
      createdAt
    }
  }
`;

export { DELETE_FOOTPRINT, GET_FOOTPRINTS };
