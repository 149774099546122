import { v4 as uuid } from 'uuid';

const KEY_LOCAL_STORAGE_TOKEN = `token`;

function getDecoded(token: string) {
  if (!token) {
    return ``;
  }
  const decoded = new Buffer(token, 'base64');
  return decoded.toString(`utf-8`);
}

function getEncoded(value: string) {
  const buffer = new Buffer(value);
  const encoded = buffer.toString('base64');
  return encoded;
}

function getDetails(token: string) {
  if (!token) {
    return [];
  }
  const decoded = getDecoded(token);
  console.log(`getDetails() - decoded:`, decoded);
  return decoded.split(`:`);
}

function getToken() {
  const token = localStorage.getItem(KEY_LOCAL_STORAGE_TOKEN) || ``;
  console.log(`getToken() - token:`, token);
  const details = getDetails(token);
  console.log(`getToken() - details:`, details);
  if (details.length === 5) {
    return token;
  }
  details.splice(0, details.length);
  /* eslint-disable new-parens */
  const stamp = +new Date();
  const id = uuid();
  const {
    navigator: { userAgent },
  } = window;
  console.log(`getToken() - userAgent:`, userAgent);
  details.push(`${stamp}`);
  details.push(id);
  details.push(getEncoded(userAgent));
  const { REACT_APP_HOST_ENV, REACT_APP_HOST_URL } = process.env;
  details.push(REACT_APP_HOST_ENV || '');
  details.push(getEncoded(REACT_APP_HOST_URL || ''));
  // Format: `stamp:id:userAgent`
  const composite = details.join(`:`);
  const encoded = getEncoded(composite);
  localStorage.setItem(KEY_LOCAL_STORAGE_TOKEN, encoded);
  return encoded;
}

const getTokenFromDocumentLocation = (): string => {
  const { pathname } = document.location;
  if (!pathname) {
    return ``;
  }
  if (0 === pathname.indexOf(`/q/`)) {
    const filename = pathname.replace(/^\/q\//, '');
    const split = filename.split(`.`);
    return split[0];
  }
  return ``;
};

export { getToken, getTokenFromDocumentLocation };
