import { Dialog, FormField, Pane, Tab, TextInputField } from 'evergreen-ui';
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { DialogAccountJoinFooter } from './DialogAccountJoinFooter';
import { TOGGLE_TYPE, toggleFormElement } from '../../../../helper/form';

// interface StateAccountRegister {
//   isLoading: boolean;
//   isShown: boolean;
//   form: {
//     registerEmail: string;
//     registerMobile: string;
//   },
// }

const STATE_ACCOUNT_REGISTER_DEFAULT = {
  isLoading: false,
  isShown: false,
  form: {
    registerEmail: ``,
    registerMobile: ``,
  },
};

const STATE_ACCOUNT_REGISTER_SUBMIT = {
  isLoading: true,
  isShown: true,
};

const STATE_ACCOUNT_REGISTER_IS_SHOWN = {
  isLoading: false,
  isShown: true,
};

// const qlippedRegister = (e: SyntheticEvent, setState: Function) => {
//   e.stopPropagation();
//   e.nativeEvent.stopImmediatePropagation();
//   setState(STATE_ACCOUNT_REGISTER_IS_SHOWN);
// }

const fakeRegister = (setState: Function, window: Window) => {
  window.setTimeout(() => {
    setState(STATE_ACCOUNT_REGISTER_DEFAULT);
  }, 2000);
};

const DialogAccountJoin = () => {
  const [state, setState] = useState(STATE_ACCOUNT_REGISTER_DEFAULT);
  // @ts-ignore
  const { token } = useParams();
  const registerEmailRefFormField = useRef<HTMLElement>(null);
  const registerEmailRefInput = useRef<HTMLInputElement>(null);
  const registerMobileRefFormField = useRef<HTMLElement>(null);
  const registerMobileRefInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    console.log(`DialogAccountRegister.useEffect() - token:`, token);
    console.log(`DialogAccountRegister.useEffect() - state:`, state);
    // inputEmailRef.current.focus();
    if (state.isLoading === true) fakeRegister(setState, window);
    console.log(`DialogAccountRegister.useEffect() - inputRegisterEmailRef:`, registerEmailRefInput);
  }, [token, state]);
  function register(e: SyntheticEvent) {
    // qlippedRegister(e, setState);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setState({ ...state, ...STATE_ACCOUNT_REGISTER_IS_SHOWN });
  }
  function handleChangeRegisterEmail(e: ChangeEvent<HTMLInputElement>) {
    console.log(`DialogAccountRegister.handleChange() - e.target.value:`, e.target.value);
    console.log(`DialogAccountRegister.handleChange() - inputRegisterEmailRef:`, registerEmailRefInput);
    // console.log(e);
    // const newState: StateAccountRegister = {
    //   ...state,
    //   form: {
    //     registerEmail: e.target.value
    //   }
    // }
    state.form.registerEmail = e.target.value;
    setState({
      ...state,
    });
  }

  return (
    <Pane float="right">
      <Dialog
        isShown={state.isShown}
        title="Create new account"
        onCloseComplete={() => setState(STATE_ACCOUNT_REGISTER_DEFAULT)}
        onOpenComplete={() => {
          toggleFormElement({
            formFieldClass: `backstage`,
            formFieldRef: registerMobileRefFormField,
            toggleType: TOGGLE_TYPE.HIDE,
          });
          toggleFormElement({
            formFieldClass: `backstage`,
            formFieldRef: registerEmailRefFormField,
            inputRef: registerEmailRefInput,
            needsFocus: true,
            toggleType: TOGGLE_TYPE.SHOW,
          });
        }}
        isConfirmLoading={state.isLoading}
        onConfirm={() => setState({ ...state, ...STATE_ACCOUNT_REGISTER_SUBMIT })}
        confirmLabel={state.isLoading ? 'Creating account...' : 'Create new account'}
        footer={({ close }) => (
          <DialogAccountJoinFooter
            state={state}
            close={close}
            inputRegisterEmailRef={registerEmailRefInput}
            inputRegisterMobileRef={registerMobileRefInput}
            registerEmailFormFieldRef={registerEmailRefFormField}
            registerMobileFormFieldRef={registerMobileRefFormField}
          />
        )}
      >
        <FormField ref={registerEmailRefFormField}>
          <TextInputField
            label="Register with email"
            onChange={handleChangeRegisterEmail}
            placeholder="Enter email"
            ref={registerEmailRefInput}
          />
        </FormField>
        <FormField className="backstage" ref={registerMobileRefFormField}>
          <TextInputField
            label="Link Mobile"
            placeholder="Enter mobile (for one time login passcodes)"
            ref={registerMobileRefInput}
          />
        </FormField>
      </Dialog>
      <Tab key="register" is="a" id="register" onClick={register} marginLeft="8px">
        Join
      </Tab>
    </Pane>
  );
};

export { DialogAccountJoin };
