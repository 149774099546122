import { parse } from 'url';

const getDocumentLocation = (): string => {
  const { location } = document;
  console.log('website - getDocumentLocation() - location:', location);
  const { href, pathname } = location;
  console.log('website - getDocumentLocation() - pathname:', pathname);
  console.log('website - getDocumentLocation() - href:', href);
  return href;
};

const getHostname = (): string => {
  const location = getDocumentLocation();
  console.log('website - getHostname() - location:', location);
  const parsed = parse(location);
  console.log('website - getHostname() - parsed:', parsed);
  const hostname = parsed['hostname'] || parsed['host'] || '';
  console.log('website - getHostname() - hostname:', hostname);
  return hostname;
};

const getApiUrl = (hostname?: string) => {
  console.log(`getApiUrl() - process.env:`, process.env);
  console.log(`getApiUrl() - hostname:`, hostname);
  const url = hostname || getHostname();
  console.log('website - getApiUrl() - url:', url);
  if (`localhost` === url) {
    return `localhost:3000`;
  }
  const parts = url.split(`.`);
  const length = parts.length;
  const prefix = `api`;
  switch (length) {
    case 2: {
      // production
      const [secondLevel, topLevel] = parts;
      return `${prefix}.${secondLevel}.${topLevel}`;
    }
    case 3: {
      const [subDomain, secondLevel, topLevel] = parts;
      if ('www' === subDomain) {
        // production
        return `${prefix}.${secondLevel}.${topLevel}`;
      }
      const stage = `${subDomain}`;
      return `${prefix}-${stage}.${secondLevel}.${topLevel}`;
    }
  }
  // Should not reach here
  const topLevel = parts[length - 1];
  const secondLevel = parts[length - 2];
  return `${prefix}-default.${secondLevel}.${topLevel}`;
};

const isLocalhost = (hostname?: string): boolean => {
  const url = hostname || getHostname();
  console.log('website - getApiUrl() - url:', url);
  if (`localhost` !== url) {
    return false;
  }
  return true;
};

/*
const example = () => {
  const apiUrl = getApiUrl();
  console.log(`useEffect() - apiUrl:`, apiUrl);
  const uri = `https://${apiUrl}/api/proxy`;
  console.log(`useEffect() - uri:`, uri);
  const httpLink = createHttpLink({
    uri,
  });
  const authLink = setContext((request, previousContext) => ({
    headers: { authorization: `Basic: ${token}` },
  }));
  const link = authLink.concat(httpLink);
  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
  queryFootprints(client)
    .then((response) => {
      console.log(`useEffect() - response:`, response);
    })
    .catch((error) => {
      console.error(error);
    });
};
 */

export { getApiUrl, isLocalhost };
