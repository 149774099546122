import React, { useEffect } from 'react';
import { useParams } from 'react-router';
// import { useMutation } from 'react-query';

import {ArrowTopRightIcon, IconButton, Link, Paragraph, Spinner, Table, TrashIcon} from 'evergreen-ui';

// MutationResult
import {QueryResult, useMutation, useQuery} from '@apollo/client';

import { DELETE_FOOTPRINT, GET_FOOTPRINTS } from '../../helper/gql';
import { Footprint } from '../../interface';

function Footprints() {
  const { loading, error, data }: QueryResult<{ footprints: Footprint[] }> = useQuery(GET_FOOTPRINTS);
  // MutationResult<{ ... }>
  const [mutateFootprintDelete, mutateResult] = useMutation(DELETE_FOOTPRINT);
  const { token } = useParams<{ token: string }>();
  console.log(`Footprints() - token:`, token);
  useEffect(() => {
    console.log(`Footprints() - useEffect()`);
  });
  if (loading) return <Spinner size={24} />;
  if (error || !data) {
    console.log(`Footprints() - error:`, error);
    console.log(`Footprints() - data:`, data);
  }
  function deleteFootprint(footprintId: string) {
    console.log(`deleteFootprint() - footprintId:`, footprintId);
    console.log(`deleteFootprint() - token:`, token);
    console.log(`deleteFootprint() - mutateResult:`, mutateResult);
    mutateFootprintDelete({ variables: { footprintId } })
      .then((data) => {
        console.log(`mutateFootprintDelete() - data:`, data);
        console.log(`mutateFootprintDelete() - mutateResult:`, mutateResult);
      })
      .catch((reason) => {
        console.log(`mutateFootprintDelete() - reason:`, reason);
      });
  }
  return (
    <>
      <Paragraph>Qlip Pane - Footprints</Paragraph>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flexBasis={200} flexShrink={0} flexGrow={0}>
            Date
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Url</Table.TextHeaderCell>
          <Table.TextHeaderCell>Title</Table.TextHeaderCell>
          <Table.TextHeaderCell>Action</Table.TextHeaderCell>
          <Table.TextHeaderCell>Open</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {data?.footprints?.map((footprint, index) => (
            <Table.Row key={footprint.id} isSelectable onSelect={() => console.log(footprint.url)}>
              <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
                {footprint.createdAt}
              </Table.TextCell>
              <Table.TextCell>{footprint.url}</Table.TextCell>
              <Table.TextCell>{footprint.title ? footprint.title : ''}</Table.TextCell>
              <Table.TextCell>
                <IconButton icon={TrashIcon} intent="danger" onClick={() => deleteFootprint(footprint.id)} />
              </Table.TextCell>
              <Table.TextCell>
                <Link target="_blank" href={footprint.url}><IconButton icon={ArrowTopRightIcon} /></Link>
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export { Footprints };
