import React, { useState } from 'react';

import { MenuIcon, Paragraph, Position, SideSheet, Tab } from 'evergreen-ui';

function QlippedHamburger() {
  const [state, setState] = useState({ isShown: false });
  console.log(`QlippedHamburger() - state:`, state);
  console.log(`QlippedHamburger() - Position.LEFT:`, Position.LEFT);
  return (
    <>
      {[{ tab: `menu` }].map(({ tab }, index) => (
        <Tab key={tab} is="a" href="#" id={tab}>
          {(() => {
            switch (index) {
              case 0:
                return (
                  <>
                    <SideSheet
                      position={Position.LEFT as any}
                      isShown={state.isShown}
                      onCloseComplete={() => setState({ isShown: false })}
                      width={240}
                    >
                      <Paragraph margin={40}>Basic Example</Paragraph>
                    </SideSheet>
                    <MenuIcon onClick={() => setState({ isShown: true })} size={16} />
                  </>
                );
            }
          })()}
        </Tab>
      ))}
    </>
  );
}

export { QlippedHamburger };
