import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { Button } from 'evergreen-ui';
import { useParams } from 'react-router';

// @ts-ignore
const DialogAccountJoinFooter = (props) => {
  // console.log(`DialogAccountRegisterFooter() - props:`, props);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState({ ...props.state });
  const { close } = props;
  const { token } = useParams();
  const buttonContinueWithEmailRef = useRef<HTMLButtonElement>(null);
  const buttonContinueWithMobileRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    // console.log(`DialogAccountRegisterFooter.useEffect() - props:`, props);
    if (buttonContinueWithMobileRef?.current) {
      buttonContinueWithMobileRef.current.classList.add(`backstage`);
    }
    if (buttonContinueWithEmailRef?.current) {
      buttonContinueWithEmailRef.current.classList.remove(`backstage`);
    }
  }, [props, state, token]);
  function registerEmail(e: SyntheticEvent) {
    // console.log(`DialogAccountRegisterFooter.registerEmail() - props:`, props);
    // console.log(
    //   `DialogAccountRegisterFooter.registerEmail() - buttonContinueWithEmailRef.current:`,
    //   buttonContinueWithEmailRef.current,
    // );
    // qlippedRegister(e, setState);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    // setState(STATE_ACCOUNT_REGISTER_SUBMIT);
    // console.log(`DialogAccountRegisterFooter.registerEmail()`);
    if (props.registerEmailFormFieldRef?.current) {
      props.registerEmailFormFieldRef.current.classList.add(`backstage`);
    }
    if (buttonContinueWithEmailRef?.current) {
      buttonContinueWithEmailRef.current.classList.add(`backstage`);
    }
    if (props.registerMobileFormFieldRef?.current) {
      props.registerMobileFormFieldRef.current.classList.remove(`backstage`);
      props.inputRegisterMobileRef.current.focus();
    }
    if (buttonContinueWithMobileRef?.current) {
      buttonContinueWithMobileRef.current.classList.remove(`backstage`);
    }
  }
  function registerMobile(e: SyntheticEvent) {
    // console.log(`DialogAccountRegisterFooter.registerMobile() - props:`, props);
    // console.log(
    //   `DialogAccountRegisterFooter.registerMobile() - buttonContinueWithMobileRef.current:`,
    //   buttonContinueWithMobileRef.current,
    // );
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    // setState(STATE_ACCOUNT_REGISTER_SUBMIT);
    // console.log(`DialogAccountRegisterFooter.registerMobile()`);
    if (props.registerMobileFormFieldRef?.current) {
      props.registerMobileFormFieldRef.current.classList.add(`backstage`);
    }
    if (buttonContinueWithMobileRef?.current) {
      // @ts-ignore
      buttonContinueWithMobileRef.current.classList?.add(`backstage`);
    }
    if (props.registerEmailFormFieldRef?.current) {
      props.registerEmailFormFieldRef.current.classList.remove(`backstage`);
      props.inputRegisterEmailRef.current.focus();
    }
    if (buttonContinueWithEmailRef?.current) {
      buttonContinueWithEmailRef.current.classList?.remove(`backstage`);
    }
  }
  return (
    <>
      <Button appearance="minimal" marginLeft={8} onClick={close}>
        Cancel
      </Button>
      <Button appearance="primary" marginLeft={8} onClick={registerEmail} ref={buttonContinueWithEmailRef}>
        Continue with email
      </Button>
      <Button
        appearance="primary"
        className="backstage"
        marginLeft={8}
        onClick={registerMobile}
        ref={buttonContinueWithMobileRef}
      >
        Complete registration
      </Button>
    </>
  );
};
export { DialogAccountJoinFooter };
