export const STATE_ACCOUNT_DEFAULT = {
  signIn: { isLoading: false, isShown: false },
  signUp: { isLoading: false, isShown: false },
  resend: { isLoading: false, isShown: false },
  horizontal: { selectedIndex: 0, tabs: ['Footprints','Qlips'] },
};

export const STATE_ACCOUNT_SIGN_IN_SHOW = {
  signIn: { isLoading: false, isShown: true },
  signUp: { isLoading: false, isShown: false },
  resend: { isLoading: false, isShown: false },
  horizontal: { selectedIndex: 0, tabs: ['Footprints','Qlips'] },
};

export const STATE_ACCOUNT_SIGN_IN_SUBMIT = {
  signIn: { isLoading: true, isShown: true },
  signUp: { isLoading: false, isShown: false },
  resend: { isLoading: false, isShown: false },
  horizontal: { selectedIndex: 0, tabs: ['Footprints','Qlips'] },
};

export const STATE_ACCOUNT_SIGN_UP_SHOW = {
  signIn: { isLoading: false, isShown: false },
  signUp: { isLoading: false, isShown: true },
  resend: { isLoading: false, isShown: false },
  horizontal: { selectedIndex: 0, tabs: ['Footprints','Qlips'] },
};

export const STATE_ACCOUNT_SIGN_UP_SUBMIT = {
  signIn: { isLoading: false, isShown: false },
  signUp: { isLoading: true, isShown: true },
  resend: { isLoading: false, isShown: false },
  horizontal: { selectedIndex: 0, tabs: ['Footprints','Qlips'] },
}

export const STATE_ACCOUNT_RESEND_SHOW = {
  signIn: { isLoading: false, isShown: false },
  signUp: { isLoading: false, isShown: false },
  resend: { isLoading: false, isShown: true },
  horizontal: { selectedIndex: 0, tabs: ['Footprints','Qlips'] },
};
