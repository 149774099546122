import React from 'react';

import { Tab } from 'evergreen-ui';

import logo from '../logo.png';

function QlippedLogo() {
  return (
    <>
      {[{ tab: `logo` }].map(({ tab }, index) => (
        <Tab key={tab} is="a" href="#" id={tab}>
          {(() => {
            switch (index) {
              case 0: {
                return <img src={logo} alt="qlipped logo" width={100} />;
              }
            }
          })()}
        </Tab>
      ))}
    </>
  );
}

export { QlippedLogo };
