import { Badge, Button, Pane, Paragraph, SidebarTab, Tablist, TabNavigation } from 'evergreen-ui';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { STATE_ACCOUNT_DEFAULT } from '../helper/dialog';
import { QlippedHamburger } from './QlippedHamburger';
import { QlippedLogo } from './QlippedLogo';
import { getSidebarTab, QlipPane } from './QlipPane';
import { fakeRegister, fakeResend, fakeSignIn, signIn as qlippedSignIn } from '../helper/account';
import { DialogAccountJoin } from './dialog/account/join/DialogAccountJoin';
import { DialogAccountSignIn } from './dialog/account/sign-in/DialogAccountSignIn';

function Qlip() {
  const history = useHistory();
  const location = useLocation();
  console.log(`Qlip() - location:`, location);
  const { token } = useParams();
  const [state, setState] = useState(STATE_ACCOUNT_DEFAULT);
  useEffect(() => {
    console.log(`Qlip.useEffect() - token:`, token);
    console.log(`Qlip.useEffect() - state:`, state);
    if (state.signIn.isLoading === true) fakeSignIn(setState, window);
    if (state.signUp.isLoading === true) fakeRegister(setState, window);
    if (state.resend.isLoading === true) fakeResend(setState, window);
  }, [token, state]);
  function goBack() {
    history.goBack();
  }
  // function register(e: SyntheticEvent) {
  //   qlippedRegister(e, setState);
  // }
  function signIn(e: SyntheticEvent) {
    qlippedSignIn(e, setState);
  }
  /* Following qlips will expire in 30 days. Sign in to keep them. */
  return (
    <>
      <Badge display="none">Qlip - {token}</Badge>
      <Button display="none" onClick={goBack}>
        Go Back
      </Button>
      <TabNavigation marginBottom="12px" marginTop="12px" paddingLeft="12px" paddingRight="12px">
        <QlippedHamburger />
        <QlippedLogo />
        <DialogAccountSignIn />
        <DialogAccountJoin />
      </TabNavigation>
      <Button display="none" onClick={signIn}>
        Sign In
      </Button>
      <Pane display="flex" height={240}>
        <Tablist marginBottom={16} flexBasis={48} marginRight={24}>
          {state.horizontal.tabs.map((tab, index) => (
            <SidebarTab
              key={tab}
              id={tab}
              onSelect={() => {
                console.log(`state:`, state);
                const { horizontal } = state;
                setState({
                  ...state,
                  horizontal: {
                    ...horizontal,
                    selectedIndex: index,
                  },
                });
              }}
              isSelected={index === state.horizontal.selectedIndex}
              aria-controls={`panel-${tab}`}
              height={48}
              width={60}
            >
              {getSidebarTab(index)}
            </SidebarTab>
          ))}
        </Tablist>
        <Pane padding={16} background="tint1" flex="1">
          {state.horizontal.tabs.map((tab, index) => (
            <Pane
              key={tab}
              id={`panel-${tab}`}
              role="tabpanel"
              aria-labelledby={tab}
              aria-hidden={index !== state.horizontal.selectedIndex}
              display={index === state.horizontal.selectedIndex ? 'block' : 'none'}
            >
              <Paragraph>Panel {tab}</Paragraph>
              <QlipPane paneType={tab} />
            </Pane>
          ))}
        </Pane>
      </Pane>
    </>
  );
}

export { Qlip };
