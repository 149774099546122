import React from 'react';

import { HistoryIcon, Icon, PaperclipIcon, Paragraph } from 'evergreen-ui';

import { Footprints } from './qlip-pane/Footprints';

function getSidebarTab(index: number) {
  switch (index) {
    case 0:
      return <Icon icon={HistoryIcon} marginBottom={8} marginLeft={16} marginTop={8} size={16} />;
    case 1:
      return <Icon icon={PaperclipIcon} marginBottom={8} marginLeft={16} marginTop={8} size={16} />;
  }
}

function QlipPane({ paneType }: { paneType: string }) {
  console.log(`QlipPane() - paneType:`, paneType);
  function getQlipPane(paneType: string) {
    switch (paneType) {
      case 'Footprints':
        return <Footprints />;
      default:
        return (
          <>
            <Paragraph>default</Paragraph>
          </>
        );
    }
  }
  return (
    <>
      <Paragraph>Qlip Pane - {paneType}</Paragraph>
      {getQlipPane(paneType)}
    </>
  );
}

export { getSidebarTab, QlipPane };
