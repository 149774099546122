import { Dialog, FormField, Pane, Tab, TextInputField } from 'evergreen-ui';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { TOGGLE_TYPE, toggleFormElement } from '../../../../helper/form';
import { DialogAccountSignInFooter } from './DialogAccountSignInFooter';

const STATE_ACCOUNT_SIGN_IN_DEFAULT = {
  isLoading: false,
  isShown: false,
};

const STATE_ACCOUNT_SIGN_IN_SUBMIT = {
  isLoading: true,
  isShown: true,
};

const STATE_ACCOUNT_SIGN_IN_IS_SHOWN = {
  isLoading: false,
  isShown: true,
};

const fakeSignIn = (setState: Function, window: Window) => {
  window.setTimeout(() => {
    setState(STATE_ACCOUNT_SIGN_IN_DEFAULT);
  }, 2000);
};

function qlippedSignIn(e: SyntheticEvent, setState: Function) {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  setState(STATE_ACCOUNT_SIGN_IN_IS_SHOWN);
}

const DialogAccountSignIn = () => {
  const [state, setState] = useState(STATE_ACCOUNT_SIGN_IN_DEFAULT);
  const { token } = useParams();
  const signInEmailRefFormField = useRef<HTMLElement>(null);
  const signInEmailRefInput = useRef<HTMLInputElement>(null);
  const signInPasscodeRefFormField = useRef<HTMLElement>(null);
  const signInPasscodeRefInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // console.log(`DialogAccountSignIn.useEffect() - token:`, token);
    // console.log(`DialogAccountSignIn.useEffect() - state:`, state);
    if (signInPasscodeRefInput?.current) {
      signInPasscodeRefInput.current.classList.add(`backstage`);
    }
    if (signInEmailRefInput?.current) {
      signInEmailRefInput.current.classList.remove(`backstage`);
    }
    if (state.isLoading === true) fakeSignIn(setState, window);
  }, [state, token]);
  function signIn(e: SyntheticEvent) {
    qlippedSignIn(e, setState);
  }
  return (
    <Pane float="right">
      <Dialog
        isShown={state.isShown}
        title="Sign In"
        onCloseComplete={() => setState(STATE_ACCOUNT_SIGN_IN_DEFAULT)}
        onOpenComplete={() => {
          toggleFormElement({
            formFieldClass: `backstage`,
            formFieldRef: signInPasscodeRefFormField,
            toggleType: TOGGLE_TYPE.HIDE,
          });
          toggleFormElement({
            formFieldClass: `backstage`,
            formFieldRef: signInEmailRefFormField,
            inputRef: signInEmailRefInput,
            needsFocus: true,
            toggleType: TOGGLE_TYPE.SHOW,
          });
        }}
        isConfirmLoading={state.isLoading}
        onConfirm={() => setState(STATE_ACCOUNT_SIGN_IN_SUBMIT)}
        confirmLabel={state.isLoading ? 'Signing in...' : 'Sign Into qlipped.com'}
        footer={({ close }) => (
          <DialogAccountSignInFooter
            state={state}
            close={close}
            signInEmailRefInput={signInEmailRefInput}
            signInPasscodeRefInput={signInPasscodeRefInput}
            signInEmailRefFormField={signInEmailRefFormField}
            signInPasscodeRefFormField={signInPasscodeRefFormField}
          />
        )}
      >
        <FormField ref={signInEmailRefFormField}>
          <TextInputField label="Login with email" placeholder="Enter email" ref={signInEmailRefInput} />
        </FormField>
        <FormField className="backstage" ref={signInPasscodeRefFormField}>
          <TextInputField label="Enter passcode" placeholder="**********" ref={signInPasscodeRefInput} />
        </FormField>
      </Dialog>
      <Tab key="signIn" is="a" id="signIn" onClick={signIn} marginLeft="8px">
        Sign In
      </Tab>
    </Pane>
  );
};

export { DialogAccountSignIn };
