import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import './App.css';
import { BookmarkletLink } from './component/BookmarkletLink';
import { History } from './component/history/History';
import { Qlip } from './component/Qlip';
import { getToken } from './helper/token';

import input from './content/test.md';

function printEnv(label: string) {
  Object.keys(process.env).forEach((key, i) => {
    console.log(`${label} - ${i} - ${key}:`, process.env[key]);
  });
}

function App() {
  const label = `App`;
  const [markdown, setMarkdown] = useState(input);
  const [token] = useState(getToken());
  useEffect(() => {
    const prefix = `${label}.useEffect`;
    console.log(prefix);
    printEnv(prefix);
    fetch(input)
      .then((response) => response.text())
      .then((markdown) => setMarkdown(markdown));
  });
  return (
    <Router>
      <div className="App">
        <header className="App-header"></header>
        <Switch>
          <Route exact path="/">
            <div>
              <ReactMarkdown source={markdown} />
              <BookmarkletLink token={token} />
            </div>
          </Route>
          <Route exact path="/q/:token" component={Qlip} />
          <Route exact path="/history/:token" component={History} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
